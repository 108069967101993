<template>
  <div class="accoutCode-camera">
    <EBackTop :title="'扫描登陆'" background="transparent" color="#fff" />
    <div class="camera-box">
      <div class="md-login-main">
        <canvas ref="canvas_scan" class="canvas" />
        <div class="scan-view">
          <div class="line" />
          <div class="angle" />
        </div>
        <p class="scan-desc">对准二维码即可自动识别</p>

        <div class="scanning-btn">
          <div class="btn" @click="clickChoosePhone()">
            相冊
            <input ref="fileInput" type="file" accept="image/*" style="display: none" @change="pictureChange" />
            <canvas ref="qrCanvas" style="display: none" />
          </div>

          <div class="btn" @click="goCredentials">我的凭证</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { user_qrcode_info } from 'api/user'
import jsQR from 'jsqr'
import Jimp from 'jimp'
import QrCode from 'qrcode-reader'
export default {
  head() {
    return {
      title: this.title,
      script: [
        {
          // src: "https://unpkg.com/vue-qrcode-reader/dist/VueQrcodeReader.umd.min.js",
        }
      ]
    }
  },
  components: {},

  data() {
    return {
      loading: false,
      isAnimation: true,
      audio: Object,
      video: Object,
      cvsele: Object,
      canvas: Object
    }
  },

  computed: {
    popupShow({ $store }) {
      return $store.state.popup.showLoginPop
    },
    popupInfo({ $store }) {
      return $store.state.popup.popupInfo
    },
    token({ $store }) {
      return $store.state.user.token
    },
    info({ $store }) {
      return $store.state.user.info
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.video = document.createElement('video')
        this.cvsele = this.$refs.canvas_scan
        this.canvas = this.cvsele.getContext('2d')
        this.startScanning()
      }, 50)
    })
  },
  destroyed() {
    this.cance()
  },
  methods: {
    toBack() {
      return this.$router.go('-1')
    },

    startScanning() {
      this.isAnimation = true
      navigator.getUserMedia =
        navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
      if (navigator.mediaDevices) {
        navigator.mediaDevices
          .getUserMedia({
            video: { facingMode: 'environment' }
          })
          .then(stream => {
            this.video.srcObject = stream
            this.video.setAttribute('playsinline', true)
            this.video.setAttribute('webkit-playsinline', true)
            this.video.addEventListener('loadedmetadata', () => {
              this.video.play()
              this.sweep()
            })
          })
          .catch(() => {
            this.cance()
            this.$notify('对不起：未识别到扫描设备!')
          })
      } else if (navigator.getUserMedia) {
        navigator.getUserMedia(
          {
            video: { facingMode: 'environment' }
          },
          stream => {
            this.video.srcObject = stream
            this.video.setAttribute('playsinline', true)
            this.video.setAttribute('webkit-playsinline', true)
            this.video.addEventListener('loadedmetadata', () => {
              this.video.play()
              this.sweep()
            })
          },
          () => {
            this.cance()
            this.$notify('对不起：未识别到扫描设备!')
          }
        )
      } else if (navigator.userAgent.toLowerCase().match(/chrome/) && !location.origin.includes('https://')) {
        this.$notify('获取浏览器录音功能，因安全性问题，需要在localhost 或 127.0.0.1 或 https 下才能获取权限！')
      } else {
        this.cance()
        alert('对不起：未识别到扫描设备!')
      }
    },

    sweep() {
      if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
        const { videoWidth, videoHeight } = this.video
        this.cvsele.width = videoWidth
        this.cvsele.height = videoHeight
        this.canvas.drawImage(this.video, 0, 0, videoWidth, videoHeight)
        try {
          const img = this.canvas.getImageData(0, 0, videoWidth, videoHeight)

          this.imgurl = img
          const obj = jsQR(img.data, img.width, img.height, {
            inversionAttempts: 'dontInvert'
          })
          if (obj) {
            const loc = obj.location
            this.draw(loc.topLeftCorner, loc.topRightCorner)
            this.draw(loc.topRightCorner, loc.bottomRightCorner)
            this.draw(loc.bottomRightCorner, loc.bottomLeftCorner)
            this.draw(loc.bottomLeftCorner, loc.topLeftCorner)
            if (obj.data) {
              this.cance()
              this.onDecode(obj.data)
            }
          } else {
            // this.cance()
            // this.$notify('识别失败，请检查二维码是否正确！')
          }
        } catch (err) {
          this.$notify('识别失败，请检查二维码是否正确！')
        }
      }
      if (this.isAnimation) {
        this.timer = requestAnimationFrame(() => {
          this.sweep()
        })
      }
    },

    draw(begin, end) {
      this.canvas.beginPath()
      this.canvas.moveTo(begin.x, begin.y)
      this.canvas.lineTo(end.x, end.y)
      this.canvas.lineWidth = 3
      this.canvas.strokeStyle = 'red'
      this.canvas.stroke()
    },

    cance() {
      this.isAnimation = false
      cancelAnimationFrame(this.timer)
    },
    // 跳转我的凭证
    goCredentials() {
      this.$router.push('/mine/setting/accoutCode')
    },
    // 我的相册
    clickChoosePhone() {
      this.$refs.fileInput.click()
    },
    // 选择图片
    pictureChange(e) {
      const file = e.target.files[0]
      const createObjectURL = window.createObjectURL || window.URL.createObjectURL || window.webkitURL.createObjectUR
      this.result = ''
      this.imgurl = createObjectURL(file)
      this.$refs.fileInput.value = ''
      const fReader = new FileReader()
      fReader.readAsDataURL(file) // Base64 8Bit字节码
      // fReader.readAsBinaryString(file);  // Binary 原始二进制
      // fReader.readAsArrayBuffer(file);   // ArrayBuffer 文件流
      fReader.onload = e => {
        e.target.result &&
          Jimp.read(e.target.result).then(async res => {
            //第二版
            // let that=this;
            // const qr = new QrCode();
            // qr.callback = function(err, value) {
            //   if (err) {
            //     return console.error(err);
            //   }
            //   if(value.result){
            //     that.onDecode(value.result)
            //   }
            // };
            // qr.decode(res.bitmap);

            // 第一版保留
            // const { data, width, height } = res.bitmap
            try {
              const { data, width, height } = res.bitmap
              const resolve = await jsQR(data, width, height)
              // eslint-disable-next-line no-console

              this.onDecode(resolve.data)
            } catch (err) {
              // alert(JSON.stringify(err))
              this.$notify('识别失败，请检查二维码是否正确！')
            }
          })
      }
    },
    // 请求
    async onDecode(result) {
      this.result = result
      if (this.result) {
        const res = await user_qrcode_info({
          value: this.result
        })
        if (res && res.code === 200) {
          this.$notify({
            color: '#ad0000',
            background: '#ffe0b5',
            type: 'success',
            message: '扫描成功，正在跳转...'
          })
          this.$store.dispatch('setUserInfo', res.data)
          this.$store.dispatch('setToken', res.data.token)
          this.$router.push('/mine')
        } else {
          this.$notify({
            color: '#ad0000',
            background: '#ffe0b5',
            type: 'success',
            message: res.tip
          })
        }
      }
    },
    async onInit(promise) {
      this.loading = true
      try {
        await promise
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.accoutCode-camera {
  min-height: 100vh;
  font-size: 0.28rem;
  background: url('../../../assets/imgs/mine/mine_top_bg.png') center center no-repeat;
  min-height: 100vh;
}
.canvas {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
}

.scan-desc {
  position: absolute;
  top: 55%;
  left: 50%;
  font-size: 0.28rem;
  transform: translateX(-50%);
  margin-top: 15px;
}

.scan-view {
  width: 75vw;
  height: 75vw;
  max-height: 75vh;
  max-width: 75vh;
  position: absolute;
  left: 50%;
  top: 5rem;
  transform: translate(-50%, -50%);
  overflow: hidden;
  //border: 0.1rem solid rgba(255, 255, 255, 0.2);

  .line {
    height: calc(100% - 2px);
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 43%, #fff 211%);
    border-bottom: 5px solid #fff;
    transform: translateY(-100%);
    animation: radar-beam 2s infinite;
    animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
  }

  &:before,
  &:after,
  .angle:before,
  .angle:after {
    content: '';
    display: block;
    position: absolute;
    width: 3vw;
    height: 3vw;

    border: 0.2rem solid transparent;
  }
  &:before,
  &:after {
    top: 0;
    border-top-color: #fff;
  }
  .angle:before,
  .angle:after {
    bottom: 0;
    border-bottom-color: #fff;
  }

  &:before,
  .angle:before {
    left: 0;
    border-left-color: #fff;
  }
  &:after,
  .angle:after {
    right: 0;
    border-right-color: #fff;
  }
  @keyframes radar-beam {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0);
    }
  }
}
.scanning-btn {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 50%;
  top: 65%;
  transform: translate(-50%, 0);
  width: 80%;
  .btn {
    box-sizing: border-box;
    width: 40%;
    padding: 0.2rem 0;
    opacity: 0.7;
    border-radius: 0.22rem;
    background-color: $btnBg;
    font-size: 0.28rem;
    text-align: center;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    color: #333;
  }
}
.md-login {
  width: 100%;
  box-sizing: border-box;

  .scan-desc {
    width: 680px;
    font-size: 30px;
    margin: 20px auto;
    text-align: center;
  }
}
</style>
