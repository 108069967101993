var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allType-wrap"},[_c('div',{staticClass:"scopePack"},[_c('div',{staticClass:"topTabPack"},[_c('div',{staticClass:"topTab"},[_c('van-icon',{attrs:{"name":"arrow-left","size":"25"},on:{"click":function($event){return _vm.$router.push('/home')}}}),_c('ul',[_c('li',{on:{"click":function($event){return _vm.$router.push('/subpage/search')}}},[_vm._v("搜一搜")]),_c('li',{staticClass:"active"},[_vm._v("发现")]),_c('li',{on:{"click":function($event){return _vm.$router.push('/subpage/tagType')}}},[_vm._v("片库")])]),_c('i')],1)]),_c('div',{staticClass:"all_list"},[_c('ul',{staticClass:"fater_list"},_vm._l((_vm.category),function(item){return _c('li',{key:item.id,class:_vm.active === item.id ? 'activeItem' : '',on:{"click":function($event){return _vm.changeItem(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"right_info"},[(_vm.discoverAD && _vm.discoverAD.length)?_c('div',{staticClass:"top_banner"},[_c('EADSwiper',{attrs:{"imgCDN":_vm.imgCDN,"list":_vm.discoverAD,"keyName":"cover"},on:{"clickImg":_vm.clickImg}})],1):_vm._e(),_c('ul',{staticClass:"soon_list"},[_vm._l((_vm.tagList),function(tagItem){return _c('li',{key:tagItem.id,on:{"click":function($event){return _vm.$router.push({
                path: `/subpage/labelPage`,
                query: {
                  name: tagItem.name,
                  tagId: tagItem.id
                }
              })}}},[_vm._v(" "+_vm._s(tagItem.name)+" ")])}),_c('li',{staticClass:"no_bg"})],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }