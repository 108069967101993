<template>
  <div class="search-index">
    <div class="topTab">
      <van-icon name="arrow-left" size="25" @click="$router.back()" />
      <ul>
        <li class="active">搜一搜</li>
        <li @click="$router.push('/subpage/discover')">发现</li>
        <li @click="$router.push('/subpage/tagType')">片库</li>
      </ul>
      <i></i>
    </div>
    <!-- 输入框 -->
    <div class="search-input">
      <van-search v-model="value" shape="round" placeholder="收录10万+影片，5000+女优" />
      <span class="sarch-btn" @click="searchBtn">搜索</span>
    </div>
    <!-- 面板 -->
    <div class="search-main">
      <ul class="search-title">
        <li @click="changeActive(1)" :class="{ active: activeIndex === 1 }">
          视频
          <div></div>
        </li>
        <li @click="changeActive(5)" :class="{ active: activeIndex === 5 }">
          帖子
          <div></div>
        </li>
        <!--        <li @click="changeActive(2)" :class="{ active: activeIndex === 2 }">小视频</li>-->
        <!--        <li @click="changeActive(7)" :class="{ active: activeIndex === 7 }">女优</li>-->
        <!--        <li @click="changeActive(3)" :class="{ active: activeIndex === 3 }">动漫</li>-->
      </ul>
      <!-- 上拉，下拉 -->
      <div class="search-scroll">
        <ERefreshLoad
          v-if="list.length > 0"
          @refreshData="refreshData"
          @moreData="moreData"
          :finished="finished"
          :loading="loading"
          :refreshing="refreshing"
        >
          <JavFourCard v-if="activeIndex === 1" :list="list" />
          <template v-if="activeIndex === 5">
            <CommunityDefault v-for="(item, index) in list" :key="index" :itemData="item" />
          </template>
        </ERefreshLoad>
        <Nodata :img-type="5" :text="'搜索不到任何结果哦～'" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { search } from 'api/search'
export default {
  name: 'SearchResult',
  components: {
    JavFourCard: () => import('@/components/stencil/JavFourCard.vue'),
    CommunityDefault: () => import('@/components/Community/default.vue'),
    Nodata: () => import('@/components/JavNodata.vue')
  },
  data() {
    return {
      content: '',
      list: [],
      refreshing: false, // 下拉刷新开关
      loading: false, // 上拉加载
      finished: false, // 上拉加载开关
      mediaType: 0, // 1短视频 2长视频
      pageNum: 1,
      pageSize: 15,
      activeIndex: 1,
      value: ''
    }
  },
  computed: {
    historyList({ $store }) {
      return $store.getters['history/getSaerchlist']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {
    this.value = this.$route.params.value
    this.getResultList()
  },

  methods: {
    // 搜索按钮
    searchBtn() {
      this.pageNum = 1
      this.list = []
      this.getResultList()
      this.$store.dispatch('setSaerchlist', {
        type: 'add',
        value: this.value
      })
    },
    // 女优详情跳转
    toAcortDeatial(item) {
      this.$router.push(`/actor/detail/${item.id}`)
    },
    // 改变状态
    changeActive(type) {
      this.activeIndex = type
      this.refreshData()
    },
    // 清除历史记录
    clear(index) {
      this.$store.dispatch('setSaerchlist', {
        type: 'del',
        value: index
      })
    },
    // 搜索请求
    async getResultList() {
      if (this.value) {
        try {
          const res = await search({
            content: this.value,
            type: this.activeIndex,
            pageNum: this.pageNum,
            pageSize: this.pageSize
          })
          if (res.code === 200) {
            this.refreshing = false
            this.loading = false
            // 帖子为5
            if (this.activeIndex === 5) {
              this.list = [...this.list, ...res.data.postList]
              if (res.data.postList.length < this.pageSize || !res.data.postList) {
                this.finished = true
              }
            } else {
              this.list = [...this.list, ...res.data.mediaList]
              if (res.data.mediaList.length < this.pageSize || !res.data.mediaList) {
                this.finished = true
              }
            }
          } else {
            this.loading = false
            this.refreshing = false
            this.finished = true
            return this.$toast(res.tip)
          }
        } catch (error) {
          this.loading = false
          this.refreshing = false
          this.finished = true
          console.log(error)
          return this.$toast('请求出错，请稍后再试！')
        }
      } else {
        this.$toast('请输入搜索关键词')
      }
    },
    // 下拉刷新
    refreshData() {
      this.refreshing = true
      this.pageNum = 1
      this.finished = false
      this.loading = true
      this.list = []
      this.getResultList()
    },
    // 上拉加载
    moreData(loading) {
      this.loading = true
      this.pageNum += 1
      this.getResultList()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-index {
  padding: 0;
  background: url('~@/assets/imgs/mine/mine_top_bg.png');
}
.topTab {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.36rem;
  height: 1rem;
  ul {
    margin: 0 auto;
    display: flex;
    align-items: center;
    li {
      font-size: 0.32rem;
      margin: 0 0.27rem;
      color: #fff;
    }
    .active {
      font-size: 0.36rem;
    }
  }
  i {
    width: 0.4rem;
  }
}
.search-input {
  height: 1rem;
  position: fixed;
  top: 1rem;
  @include flexbox();
  padding: 0.2rem 0.36rem;
  font-size: 0.32rem;
  width: 100%;
  max-width: $pcMaxWidth;
  z-index: 10;
  color: #fff;
  .van-search {
    width: 5.74rem;
    padding: 0;
    background-color: transparent;
    :deep() {
      .van-search__content {
        padding-left: 0;
        border: 0.01rem solid #00f6f8;
        background: #292929;
      }
      .van-cell__value {
        padding-right: 0.2rem;
      }
      .van-cell {
        border-radius: 0.25rem;
        padding: 0 0 0 0.18rem;
        height: 0.72rem;
        line-height: 0.72rem;
        .van-field__left-icon {
          color: #fff;
        }
        input {
          height: 0.72rem;
          color: #fff;
        }
      }
    }
  }
}
.search-main {
  min-height: 100vh;
  padding: 3rem 0.24rem 0 0.24rem;
  .search-title {
    display: flex;
    align-items: center;
    text-align: center;
    position: fixed;
    width: 100%;
    max-width: $pcMaxWidth;
    top: 2rem;
    left: 50%;
    @include transformCenter(-50%, 0);
    z-index: 2;
    padding: 0 0.2rem 0 0.2rem;
    .active {
      color: #fff !important;
      font-weight: 600;
      font-size: 0.34rem;
      div {
        display: block !important;
      }
    }
    li {
      line-height: 0.82rem;
      width: 20%;
      height: 0.82rem;
      color: #fff;
      font-size: 0.32rem;
      div {
        width: 0.28rem;
        height: 0.08rem;
        background: #00f6f8;
        border-radius: 0.1rem;
        margin: 0 auto;
        margin-top: -0.1rem;
        display: none;
      }
    }
  }
  .search-scroll {
    height: calc(100vh - 3rem);
    overflow-x: auto;
  }
}
// 女优单独列表
.actor-list {
  @include flexbox($jc: space-between, $ai: flex-start, $fd: row, $fw: wrap);
  padding: 0.2rem;
  &-item {
    font-size: 0.24rem;
    color: #2b3346;
    margin-bottom: 0.3rem;
    text-align: center;
    margin-right: 4%;
    width: 20%;
    span {
      margin: 0;
      font-size: 0.24rem;
    }
    .actore-avatar {
      border-radius: 50%;
      margin-bottom: 0.2rem;
      :deep() {
        .warp {
          border-radius: 50%;
          width: 1.2rem;
          height: 1.2rem;
          img {
            width: 100%;
            height: 100%;
            // border-radius: 50%;
          }
        }
      }
    }
  }
  &-item:nth-child(4n) {
    margin-right: 0;
  }
}
</style>
