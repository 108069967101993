<template>
  <div class="allType-wrap">
    <div class="scopePack">
      <div class="topTabPack">
        <div class="topTab">
          <van-icon name="arrow-left" size="25" @click="$router.go('-1')" />
          <ul>
            <li @click="$router.push('/subpage/search')">搜一搜</li>
            <!-- <li @click="$router.push('/subpage/discover')">发现</li> -->
            <li class="active">片库</li>
          </ul>
          <i></i>
        </div>
        <!-- 标签盒子 -->
        <div class="tags_all_box" :class="{ 'is-top': !isUp }">
          <div class="TagBack topTabList">
            <ul class="allType-tag-all">
              <li
                :class="{ active: cardType == item.id }"
                v-for="(item, index) in TagList.videoType"
                :key="index"
                @click="fn_comicsTagAll(item.id, 5)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="TagBack topTabList">
            <ul class="allType-tag-all">
              <li
                :class="{ active: payType == item.id }"
                v-for="(item, index) in TagList.payType"
                :key="index"
                @click="fn_comicsTagAll(item.id, 2)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="TagBack topTabList">
            <ul class="allType-tag-all">
              <li
                :class="{ active: tagId == item.id }"
                v-for="(item, index) in TagList.comicsTagAllList"
                :key="index"
                @click="fn_comicsTagAll(item.id, 1)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>

          <div class="TagBack topTabList">
            <ul class="allType-tag-all">
              <li
                :class="{ active: sort == item.id }"
                v-for="(item, index) in TagList.sort"
                :key="index"
                @click="fn_comicsTagAll(item.id, 3)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
        <!-- 展开按钮 -->
        <div class="showTypeBut">
          <img
            @click="changeUp"
            :src="!isUp ? require('@/assets/imgs/up_icon.png') : require('@/assets/imgs/down_icon.png')"
            alt=""
          />
        </div>
      </div>

      <div class="search-scroll" :class="{ 'is-top': !isUp }">
        <ERefreshLoad
          @refreshData="refreshData"
          @moreData="moreData"
          :finished="finished"
          :loading="loading"
          :refreshing="refreshing"
        >
          <div class="list-wrap">
            <ul>
              <JavFourCard v-if="location === 1" :list="comicsList" />
              <JavShortSix typeTxt="搜索" :list="comicsList" v-else-if="location === 2" />
            </ul>
          </div>
        </ERefreshLoad>
      </div>
    </div>
  </div>
</template>
<script>
import { hot_tag_list, tag_category } from 'api/search'
export default {
  name: 'TagType3',
  components: {
    JavFourCard: () => import('@/components/stencil/JavFourCard.vue'),
    JavShortSix: () => import('@/components/stencil/JavShortSix.vue')
  },
  data() {
    return {
      // 加载刷新
      pageNum: 1,
      pageSize: 10,
      location: 1,
      refreshing: false,
      loading: false,
      finished: false,
      cardType: 1,
      tagId: 0, // 标签
      area: undefined, // 地区
      payType: 0, // 付费类型
      sort: 0, // 排序
      TagList: {
        comicsTagAllList: [],
        payType: [
          { name: '全部标签', id: 0 },
          { name: '免费', id: 3 },
          { name: 'VIP', id: 1 },
          { name: '付费解锁', id: 2 }
        ],

        sort: [
          {
            name: '综合排序',
            id: 0
          },
          {
            name: '播放最多',
            id: 1
          },
          {
            name: '收藏最多',
            id: 2
          },
          {
            name: '最新上架',
            id: 3
          }
        ],
        location: [
          {
            name: '长视频',
            id: 1
          },
          {
            name: '短视频',
            id: 2
          }
        ],
        videoType: [
          {
            name: '推荐',
            id: 1
          },
          {
            name: '暗网',
            id: 2
          }
          // {
          //   name: '暗网',
          //   id: 3,
          // },
        ]
      },
      // 列表
      comicsList: [],
      isUp: true
    }
  },
  computed: {},
  methods: {
    // 展开标签
    changeUp() {
      this.isUp = !this.isUp
    },
    changeLocation(num) {
      this.location = num
      this.getComicsTagAll().then(() => {
        this.tagId = '全部'
        this.refreshData()
      })
    },
    // 标签导航
    fn_comicsTagAll(item, Type) {
      if (Type == 1) {
        this.tagId = item
      } else if (Type == 2) {
        this.payType = this.payType === item ? undefined : item
      } else if (Type == 3) {
        this.sort = item
      } else if (Type == 4) {
        this.location = item
      } else if (Type == 5) {
        this.cardType = item
      }

      this.refreshData()
    },
    //所有标签
    async getComicsTagAll() {
      const res = await hot_tag_list({
        location: this.location //排序: 1:AV 2:小视频 3:动漫
      })
      if (res.code === 200 && res.data) {
        //无标签默认第一个
        if (this.$route.query.name) {
          res.data.tagList.forEach((element, index) => {
            if (this.$route.query.name == element.name) {
              this.tagId = res.data.tagList[index].id
            }
          })
        }
        this.TagList.comicsTagAllList = [{ name: '全部标签', id: 0 }, ...res.data.tagList]
        this.getComicsTagListById()
      } else {
        return this.$toast(res.tip)
      }
    },
    //获取视频列表
    async getComicsTagListById(type) {
      const res = await tag_category({
        location: this.cardType,
        priceType: this.payType,
        // cardType: this.cardType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sortType: this.sort,
        mediaTagId: this.tagId,
        mediaType: 1
      })
      if (res.code === 200 && res.data) {
        this.refreshing = false
        this.loading = false
        if (!res.data || !res.data.mediaList || res.data.mediaList.length < this.pageSize) {
          this.finished = true
        }
        if (type == 'pull') this.comicsList = []
        if (res.data.mediaList) this.comicsList = [...this.comicsList, ...res.data.mediaList]
      } else {
        this.finished = true
        this.refreshing = false
        this.loading = false
        return this.$toast(res.tip)
      }
    },
    // 排序: 1:AV 2:小视频 3:动漫
    // clickImg(element){
    //   this.$router.push(`/play/longVideo/${element.id}`);
    // },

    clickImg(item) {
      if (item.videoType == 2) {
        let newIndex = 0
        this.comicsList.forEach((element, index) => {
          if (element.id == item.id) {
            newIndex = index
          }
        })
        this.$store.commit('sortVideo/setSortVideoList', {
          list: this.comicsList,
          clickIndex: newIndex
        })
        this.$router.push(`/play/shortVideo/`)
      } else {
        this.$router.push(`/play/longVideo/${item.id}`)
      }
    },
    //上拉加载更多
    async moreData() {
      this.loading = true
      this.pageNum += 1
      this.getComicsTagListById()
    },
    // 刷新
    refreshData() {
      this.refreshing = true
      this.pageNum = 1
      this.finished = false
      this.loading = true
      this.getComicsTagListById('pull')
    }
  },

  mounted() {
    this.getComicsTagAll()
  }
}
</script>

<style lang="scss" scoped>
.allType-wrap {
  color: #fff;
  background: url('~@/assets/imgs/mine/mine_top_bg.png');

  .topTab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.36rem;
    height: 1rem;
    width: 7.5rem;
    z-index: 9999;

    ul {
      margin: 0 auto;
      display: flex;
      align-items: center;

      li {
        font-size: 0.32rem;
        margin: 0 0.27rem;
        color: #fff;
      }

      .active {
        font-size: 0.36rem;
      }
    }

    i {
      width: 0.4rem;
    }
  }

  .scopePack {
    padding-top: 1rem;
    overflow: hidden;
    position: relative;

    .topTabPack {
      width: 7.5rem;
      padding-bottom: 0.06rem;
      position: fixed;
      top: 0;
      z-index: 10;
      background: #000;

      .TagBack {
        display: flex;
        align-items: center;
        font-size: 0.28rem;
        padding: 0 0.3rem;
        padding-bottom: 0.04rem;

        .topTag {
          color: #fff;
          width: 0.8rem;
          font-size: 0.3rem;
          font-weight: 500;
        }

        .allType-tag-all {
          width: 100%;
          overflow-x: auto;
          display: flex;

          &::-webkit-scrollbar {
            display: none;
          }

          .label {
            background: #00f6f8;
            margin-right: 0.2rem;
          }

          li {
            white-space: nowrap;
            text-align: center;
            height: 0.5rem;
            display: flex;
            align-items: center;
            padding: 0.02rem 0.16rem;
            border-radius: 0.1rem;
            color: #fff;
            font-weight: 400;

            &:nth-child(5n) {
              margin-right: 0;
            }

            &.active {
              font-weight: 500;
              color: #333;
              border-radius: 0.08rem;
              background: #00f6f8;
            }
          }
        }
      }

      .topTabList {
        // padding: 0.14rem 0.3rem;
        margin-bottom: 0.1rem;
        border-top: 0.01rem solid rgba(0, 0, 0, 0.1);
        border-bottom: 0.01rem solid rgba(0, 0, 0, 0.1);
      }
    }

    .search-scroll {
      padding-top: 3.5rem;
      min-height: 100vh;
      transition: all 1s;
      &.is-top {
        padding-top: 0.8rem;
      }
    }

    .van-pull-refresh {
      .list-wrap {
        padding: 0 0.3rem;

        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          :deep() {
            .veryVersion {
              width: 3.4rem;
            }
          }
        }
      }
    }
  }
}
.showTypeBut {
  text-align: center;
  img {
    width: 0.88rem;
    height: 0.32rem;
  }
}
.tags_all_box {
  height: fit-content;
  transition: all 1s;
  &.is-top {
    height: 0;
    display: none;
  }
}
</style>
