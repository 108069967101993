<template>
  <div class="search-index">
    <div class="topTab">
      <van-icon name="arrow-left" size="25" @click="$router.back()" />
      <ul>
        <li class="active">搜一搜</li>
        <!-- <li @click="$router.push('/subpage/discover')">发现</li> -->
        <li @click="$router.push('/subpage/tagType')">片库</li>
      </ul>
      <i></i>
    </div>
    <!-- 输入框 -->
    <div class="search-input">
      <van-search v-model="value" shape="round" placeholder="收录10万+影片，5000+女优" />
      <span class="sarch-btn" @click="searchBtn">搜索</span>
    </div>

    <div class="search-main">
      <!-- 历史记录 -->
      <ul class="history" v-if="historyList.length > 0">
        <li class="history-item">
          <ul>
            <li v-for="(item, index) in historyList" :key="item">
              <div class="lable-title" @click="toSearchType(item)">
                {{ item }}
              </div>
              <div class="clear">
                <img @click="clear(index)" src="@/assets/imgs/search/clear.svg" alt="" />
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <div class="history-nodata" v-else>暂无搜索记录</div>
      <!-- 广告位 -->
      <div class="top_banner" v-if="searchAD && searchAD.length">
        <EADSwiper :imgCDN="imgCDN" :list="searchAD" keyName="cover" @clickImg="clickImg" />
      </div>
      <!-- 热点 -->
      <div class="hot-main">
        <div class="title">
          <span class="line"></span>

          热搜总榜
        </div>
        <ul class="hot-list">
          <li v-for="(item, index) in hotList" @click="toSearchType(item.content)" :key="item.content">
            <div class="sort_index">
              <span :class="index == 0 ? 'first' : index == 1 ? 'second' : index == 2 ? 'third' : ''">{{ index }}</span>
              <span> {{ item.content }}</span>
            </div>
            <div class="value_num">
              <img src="@/assets/imgs/search_value_icon.png" alt="" />
              {{ item.value | numberFilter }}热度
            </div>
          </li>
        </ul>
      </div>

      <!-- <div class="recommend-pack" v-if="avMediaList && avMediaList.length">
        <div class="recommend-top">
          <div>强力推荐</div>
      
        </div>
        <div class="recommend-list">
          <JavFourCard v-if="tabId === 1" :list="avMediaList" />
        </div>
        <div class="btn">
          <div @click="getHotChangeOne2"> <img src="@/assets/imgs/index/exchangeIcon.png" alt="">换一批</div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { search_hot2, hot_changeOne } from 'api/search'
import { advertiseClick } from 'api/home'
import { handleParamsRouteJump, handleURlParams, isIphonex } from '@/utils/utils_tools'
export default {
  name: 'SearchIndex',
  components: {
    JavFourCard: () => import('@/components/stencil/JavFourCard.vue'),
    JavShortSix: () => import('@/components/stencil/JavShortSix.vue')
  },
  data() {
    return {
      value: '',
      hotList: [],
      avMediaList: [],
      shortMediaList: [],
      tabList: [
        { name: '长视频', id: 1 },
        { name: '短视频', id: 2 }
      ],
      tabId: 1
    }
  },
  computed: {
    historyList({ $store }) {
      const arr = JSON.parse(JSON.stringify($store.getters['getSaerchlist'].reverse())).splice(0, 8)
      return arr
    },
    searchAD({ $store }) {
      return $store.getters['short_videoAD']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {
    this.getHotList2()
  },
  methods: {
    // 活动广告跳转
    async clickImg(item) {
      const code = handleURlParams(item.href)
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      const res = await advertiseClick({
        id: item.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },
    // 清空历史记录
    claerHistory() {
      this.$store.dispatch('setSaerchlist', {
        type: 'remove',
        value: []
      })
    },
    // 搜索按钮
    searchBtn() {
      if (this.value) {
        this.$store.dispatch('setSaerchlist', {
          type: 'add',
          value: this.value
        })

        this.$router.push(`/subpage/search/result/${this.value}`)
        this.value = null
      } else {
        return this.$toast('请输入您想搜索的内容')
      }
    },
    // 跳转搜索结果
    toSearchType(content) {
      if (content) {
        this.$store.dispatch('setSaerchlist', {
          type: 'add',
          value: content
        })
        this.$router.push(`/subpage/search/result/${content}`)
        content = null
      }
    },
    // 跳转排行榜
    toRank() {
      this.$router.push(`/rank`)
    },
    // 清除历史记录
    clear(index) {
      this.$store.dispatch('setSaerchlist', {
        type: 'del',
        value: index
      })
    },
    // 强力推荐
    async getHotList2() {
      const res = await search_hot2()
      if (res.code === 200) {
        this.hotList = res.data.list
        this.avMediaList = res.data.avMediaList
        this.shortMediaList = res.data.shortMediaList
      } else {
        return this.$toast('请求失败，请稍后再试')
      }
    },
    // 强力推荐换一换
    async getHotChangeOne2() {
      const res = await hot_changeOne({ type: this.tabId })
      if (res.code === 200) {
        if (this.tabId == 1) {
          this.avMediaList = res.data.avMediaList
        } else {
          this.shortMediaList = res.data.shortMediaList
        }
      } else {
        return this.$toast('请求失败，请稍后再试')
      }
    }
  }
}
</script>
  
<style lang="scss" scoped>
.search-index {
  padding: 0;
  overflow: hidden;
  background: url('~@/assets/imgs/mine/mine_top_bg.png');
}

.topTab {
  position: fixed;
  top: 0;
  left: auto;
  // right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.36rem;
  height: 1rem;
  width: 7.5rem;

  ul {
    margin: 0 auto;
    display: flex;
    align-items: center;

    li {
      font-size: 0.32rem;
      margin: 0 0.27rem;
    }

    .active {
      font-size: 0.36rem;
    }
  }

  i {
    width: 0.4rem;
  }
}

.search-input {
  height: 1rem;
  position: fixed;
  @include flexbox();
  top: 1rem;
  padding: 0.2rem 0.36rem;
  font-size: 0.32rem;
  width: 100%;
  max-width: $pcMaxWidth;
  color: #fff;
  z-index: 10;

  .van-search {
    width: 5.74rem;
    padding: 0;
    background-color: transparent;

    :deep() {
      .van-search__content {
        padding-left: 0;
        border: 0.01rem solid #00f6f8;
        background: #292929;
      }

      .van-cell__value {
        padding-right: 0.2rem;
      }

      .van-cell {
        border-radius: 0.25rem;
        padding: 0 0 0 0.18rem;
        height: 0.72rem;
        line-height: 0.72rem;

        //background: #fff;
        //border: 0.02rem solid rgba(0, 0, 0, 0.6);
        input {
          height: 0.72rem;
          color: #fff;
        }

        .van-field__left-icon {
          color: #939496;
        }
      }
    }
  }

  .sarch-btn {
    color: #fff;
  }
}

.search-main {
  min-height: 100vh;
  padding: 2rem 0 0.4rem 0;
}

// 记录
.history {
  font-size: 0.22rem;
  width: 100%;
  padding: 0 0.44rem 0.3rem 0.44rem;
  margin: 0 0.36rem;
  border-bottom: 0.01rem solid #cecece;

  &-item {
    overflow: auto;

    ul {
      width: 100%;
      padding: 0;

      li:first-child {
        font-size: 0.24rem;
        font-weight: 400;
      }

      li {
        margin-top: 0.2rem;
      }
    }
  }

  li {
    @include flexbox();
  }

  li:first-child {
    font-size: 0.28rem;
  }

  .lable-title {
    @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: nowrap);
    color: #939496;

    img {
      width: 0.23rem;
      height: 0.23rem;
      margin-right: 0.15rem;
    }
  }

  .clear {
    text-align: center;
    min-width: 1.2rem;

    img {
      width: 0.158rem;
      height: 0.158rem;
    }
  }
}

// 没有搜索记录
.history-nodata {
  height: 2rem;
  text-align: center;
  width: 100%;
  line-height: 2rem;
  box-shadow: $shadow;
  font-size: 0.32rem;
  color: #939496;
}

// 热点
.hot-main {
  padding: 0.38rem 0.34rem;
  font-size: 0.24rem;
  .line {
    border-radius: 0.18rem;
    background: #00f6f8;
    width: 0.1rem;
    height: 0.28rem;
    display: inline-block;
  }
  .title {
    font-size: 0.32rem;
  }

  .hot-list {
    li {
      @include flexbox();
      margin-top: 0.32rem;
      .sort_index {
        span:first-child {
          width: 0.34rem;
          height: 0.34rem;
          border-radius: 50%;
          text-align: center;
          line-height: 0.35rem;
          background: #4a4a4a;
          display: inline-block;

          margin-right: 0.16rem;
        }
        .first {
          background: linear-gradient(144deg, #e87000 0%, #d29800 99.52%) !important;
        }
        .second {
          background: linear-gradient(144deg, #006be8 0%, #00d2c6 99.52%) !important;
        }
        .third {
          background: linear-gradient(144deg, #e400e8 0%, #5000d2 99.52%) !important;
        }
      }
      .value_num {
        @include flexbox($jc: flex-start);
        color: #ffcb7c;
        font-size: 0.24rem;
        font-weight: 400;
        img {
          width: 0.24rem;
          margin-right: 0.06rem;

          height: 0.24rem;
        }
      }
    }
  }
}

.banner {
  width: 100%;
  height: 1.8rem;
}

.recommend-pack {
  padding: 0 0.36rem;

  .recommend-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.32rem;

    ul {
      display: flex;
      align-items: center;
      border-radius: 1rem;
      border: 0.01rem solid #00f6f8;
      padding: 0.04rem;

      li {
        width: 1.04rem;
        height: 0.44rem;
        line-height: 0.44rem;
        border-radius: 1rem;
        color: #787979;
        font-size: 0.24rem;
        text-align: center;

        &.active {
          background: #00f6f8;
          color: #333;
        }
      }
    }
  }

  .recommend-list {
    margin-top: 0.2rem;

    :deep() {
      .long-four {
        .short-six-item {
          width: 3.26rem;
        }
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;

    div {
      width: 50%;
      height: 0.84rem;
      line-height: 0.84rem;
      font-size: 0.28rem;
      margin: 0 auto;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #000;
      border-radius: 1rem;

      img {
        width: 0.26rem;
        height: 0.26rem;
        margin-right: 0.05rem;
      }
    }
  }
}
.top_banner {
  width: 6.78rem;
  height: 1.5rem;
  margin: 0.2rem auto;
  border-radius: 0.12rem;
  :deep() {
    img {
      object-fit: cover !important;
      border-radius: 0.12rem;
    }
  }
}
</style>
  