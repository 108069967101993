<template>
  <van-popup v-model="isShow" @close="close">
    <div class="share-pop">
      <img src="@/assets/imgs/subpage/share-bg.png" alt="" />
      <div>
        <div class="left" ref="sharePopup">
          <EGenerateImg ref="EGenerateImg" :link="qrCode" :config="config"></EGenerateImg>
        </div>
        <div class="right">
          <div @click="copyLink">复制链接</div>
          <div @click="savePicture">保存图片</div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import html2canvas from 'html2canvas'
export default {
  data() {
    return {
      isShow: false,
      qrCode: '',
      config: {
        qrBgwidth: '2rem'
      }
    }
  },
  props: {
    sharePopShow: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
    copyLink() {
      this.$copyText(this.qrCode).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },
    savePicture() {
      this.$toast('请截图保存！')
      // html2canvas(this.$refs.sharePopup, {
      //   dpi: 300,
      //   scale: 2,
      //   useCORS: true // 解决文件跨域问题
      // }).then(canvas => {
      //   canvas.toBlob(
      //     blob => {
      //       const url = URL.createObjectURL(blob)
      //       // 可以上传后端或者直接显示
      //       this.downloadIamge(url, '邀请二维码.png')
      //     },
      //     'image/jpeg',
      //     0
      //   )
      // })
    },
    downloadIamge(href, name) {
      const eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      document.body.appendChild(eleLink)
      eleLink.click()
      document.body.removeChild(eleLink)
      this.$toast('如果无法下载图片，请截图保存')
    }
  },
  watch: {
    sharePopShow(n, o) {
      this.isShow = n
    }
  },
  computed: {
    // 获取渠道参数
    channel({ $store }) {
      return $store.getters['getChannel']
    }
  },
  created() {
    const channel = this.channel
    this.qrCode = window.location.href
    if (channel.hasOwnProperty('dc') || channel.hasOwnProperty('pc')) {
      this.qrCode.concat(`?pc=${channel.dc}`)
    } else if (channel.hasOwnProperty('pc')) {
      this.qrCode.concat(`?pc=${channel.pc}`)
    }
  }
}
</script>

<style lang="scss">
.van-popup--center {
  background-color: transparent;
}
.share-pop {
  width: 6rem;
  border-radius: 0.16rem;
  border: 1.4px solid #535353;
  background: #070707;
  padding: 0.16rem 0.16rem 0.5rem 0.16rem;
  > img {
    width: 100%;
  }
  > div {
    padding: 0.32rem 0.21rem 0;
    border-top: 0.01rem solid #535353;
    margin-top: 0.32rem;
    display: flex;
  }
  .left {
    width: 2.1rem;
    height: 2.1rem;
    background-color: #fff;
    border-radius: 0.08rem;
    margin-right: 0.48rem;
    position: relative;
    .template {
      width: 100%;
      height: 100%;
      .qrBg {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    > div {
      flex: 1;
      border-radius: 0.08rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > div:nth-child(1) {
      background: #292929;
      margin-bottom: 0.24rem;
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.28rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.34rem; /* 121.429% */
    }
    > div:nth-child(2) {
      background: #00f6f8;
      color: #333;
      font-family: PingFang SC;
      font-size: 0.28rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.34rem; /* 121.429% */
    }
  }
}
</style>