<!--
使用方法
   <EFootBar :data="tabbars" inactive-color="#000" active-color="red" background="#fff" :height="'1.55rem'" @change="click()" />

    data:list数据对象
    inactive-color：默认字体颜色
    active-color:选中字体颜色
    height：EFootBar高度
    background：EFootBar背景色
    @change：切换事件钩子

    tabbars格式
      tabbars: [
        {
          title: '首页', //按钮名称
          to: {
            path: '/', //跳转内部路由
          },
          url: '', //跳转外部链接（需把to设置为空）
          icon: {
            active: require('~/static/images/footer/home-active.png'), //选中图片链接
            defaultActive: require('~/static/images/footer/home.png'), //默认图片链接
          },
          dot: true, //是否显示红点标记（优先展示badge）
          badge: 10, //是否显示右上角数字标记
        },
       ]
-->

<template>
  <div class="FootBar">
    <van-tabbar
      v-model="active"
      :style="styleVar"
      :fixed="true"
      :safe-area-inset-bottom="true"
      :active-color="activeColor"
      :inactive-color="inactiveColor"
      @index-bar-index-line-height="100"
      @change="change"
      :placeholder="placeholder"
      :route="true"
    >
      <van-tabbar-item :name="item.defaultActive" v-for="(item, index) in data" :key="index" :to="item.to" :dot="!!item.dot ? item.dot : false" :badge="!!item.badge ? item.badge : null">
        {{ item.title }}
        <template #icon="props">
          <img :src="props.active ? item.icon.active : item.icon.defaultActive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: 'EFootBar',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => {
        //初始化数组
        return [];
      },
    },
    defaultActive: {
      type: Number, //默认选中路由 选中路由暴露
      default() {
        return 0;
      },
    },
    height: {
      type: String,
      default: () => {
        return '50px';
      },
    },
    background: {
      type: String,
      default: () => {
        return '#fff';
      },
    },
    isIphonex: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    placeholder: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    activeColor: {
      type: String,
      default: '#ff0000',
    },
    inactiveColor: {
      type: String,
      default: '#ff0000',
    },
  },

  computed: {
   
    styleVar() {
      return {
        '--height': this.height,
        '--background': this.background,
        zIndex: 100,
      };
    },
  },

  data() {
    return {
      active: this.defaultActive,
    };
  },
  mounted() {
    console.log(this.defaultActive, this.active, this.data);
  },
  methods: {
    change(e) {
console.log(e,this.active);
      this.$emit('change', this.active);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep{
  .van-tabbar {
    max-width: 7.5rem;
    left: 50%;
    transform: translateX(-50%); //  兼容Pc
    background-color: var(--background);
    height: var(--height);
  }
}
.isIphonex {
  ::v-deep {
    .van-tabbar {
      padding-bottom: 0.25rem;
    }
  }
}
</style>
