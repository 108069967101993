<template>
  <div class="play-main">
    <JavVideo
      :videoInfo="videoInfo"
      :hasPreview="hasPreview"
      ref="videoPlayNow"
      @skipPreview="skipPreview"
      @currentTime="fn_currentTime"
      v-if="videoInfo && videoInfo.id"
    />
    <!-- <van-icon name="arrow-left" /> -->
    <EBackTop class="return" background="transparent" :open-fallback="true" :openFallback="true" color="#fff">
    </EBackTop>
    <div class="packFree">
      <div class="freeTimes" v-if="videoInfo.payType == 1 && !isVipMember">
        免费次数剩余：<span>{{ watchCount }}</span>
      </div>
      <div class="freeTimes" v-if="videoInfo.payType == 2 && !videoInfo.isBuy" @click="videoPlay(1)">
        <span>{{ videoInfo.price / 100 }}金币</span> 解锁该视频
      </div>
    </div>

    <!-- 视频播放购买提示 -->
    <div class="playWaring" v-if="showPorp">
      <div v-if="code === 6033" class="goldTip">
        <van-icon name="cross" size="28" color="#fff" @click="showPorp = false" />
        <div class="goldTip-first">
          <h2>本片需要付费观看</h2>
          <p>本片需要付费观看，请您购买后继续观看</p>
          <div class="btn">
            <!-- <span @click="videoPlay(1)">{{ videoInfo.price | changeGold }}金币</span> -->
            <div @click="videoPlay(1)" class="movie-gold-btn">
              <img src="@/assets/imgs/mine/gold.png" alt="" />
              {{ videoInfo.price | changeGold }}
            </div>
            <div @click="videoPlay(2)" class="movie-tickets-btn">
              <img src="@/assets/imgs/movie_icon.png" alt="" />
              观影券：{{ user.movieTickets ? user.movieTickets : 0 }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="code === 6018" class="goldTip-noGold">
        <van-icon name="cross" size="28" color="#fff" @click="showPorp = false" />
        <div class="goldTip-noGold-first">
          <h2>您的余额不足以支付本片</h2>
          <p>您的钱包余额不足,请充值金币后继续购买观看</p>
          <div class="btn">
            <span @click="$router.push('/mine/myWallet')">前往充值</span>
            <span class="txt-btn" @click="showPorp = false">忍着不看</span>
          </div>
        </div>
      </div>
      <div v-else-if="[6031, 6032].includes(code) && videoInfo.location == 2" class="of-limits-area">
        <div @click="showPorp = false">
          <img src="@/assets/imgs/subpage/close.svg" alt="" />
        </div>
        <div class="info">
          <div>本片需要暗网会员观看</div>
          <div>请您购买后继续观看</div>
          <div @click="$router.push('/mine/vip')">
            <span>前往开通</span>
            <span>暗网</span>
            <span>VIP</span>
          </div>
        </div>
      </div>
      <div class="vipTip" v-else>
        <van-icon name="cross" size="28" color="#fff" @click="showPorp = false" />
        <div class="vipTip-first">
          <h2>免费观看次数已用完</h2>
          <p>您今日的观看次数已经全部用完,开通会员可以无限畅看</p>
          <div class="btn">
            <span @click="$router.push('/mine/myShouyi')">邀请好友</span>
            <span @click="$router.push('/mine/vip')">开通会员</span>
          </div>
          <span>分享成功可以免费获得1天会员哦</span>
        </div>
      </div>
    </div>
    <!-- ="code === 6031 || code === 6032 || code === 6148" -->

    <!-- 活动倒计时24小时 -->
    <div class="activity_time" v-if="hourT > 0 && !isVipMember">
      <img src="@/assets/imgs/activity_icon.png" alt="" />
      <span>新用户升级会员特惠</span>
      <van-count-down :time="hourT" millisecond>
        <template #default="timeData">
          <span class="advertiseTimeOut-block">{{ timeData.hours >= 10 ? timeData.hours : `0${timeData.hours}` }}</span>
          <span class="advertiseTimeOut-txt">:</span>
          <span class="advertiseTimeOut-block">{{
            timeData.minutes >= 10 ? timeData.minutes : `0${timeData.minutes}`
          }}</span>
          <span class="advertiseTimeOut-txt">:</span>
          <span class="advertiseTimeOut-block">{{
            timeData.seconds >= 10 ? timeData.seconds : `0${timeData.seconds}`
          }}</span>
        </template>
      </van-count-down>
      <div class="btn" @click="$router.push('/mine/vip')">立即开通</div>
    </div>
    <!-- 标题 -->
    <div class="video-title">
      <h2>{{ videoInfo.title }}</h2>
      <div class="video-duration">
        <span
          >完整版时长 {{ videoInfo.playTime | secondToDate }} · 播放{{ videoInfo.watchTimes | numberFilter }}次</span
        >
        <div class="lineSelection" @click="lineSelectionShow = !lineSelectionShow">
          {{ lineSelectionName }}<img src="@/assets/imgs/upHome/xialaIcon.png" alt="" />
          <ul v-if="lineSelectionShow">
            <li v-for="(item, index) in lineSelectionList" :key="index" @click.stop="fn_switch(item)">{{ item }}</li>
          </ul>
        </div>
      </div>
      <ul class="tag-pack">
        <li
          v-for="(item, index) in videoInfo.tagList"
          :key="index"
          @click="
            $router.push({
              path: `/subpage/labelPage`,
              query: {
                name: item.name,
                tagId: item.id
              }
            })
          "
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="desc" v-if="videoInfo.desc">
        <h3>剧情简介</h3>
        <div :class="{ font: isDown }">{{ videoInfo.desc }}</div>
        <div class="iconSty">
          <van-icon :name="isDown ? 'arrow-down' : 'arrow-up'" color="#71f2f6" size=".24rem" />
          <span @click="isDown = !isDown">{{ isDown ? '展开' : '收起' }}</span>
        </div>
      </div>

      <div class="video-detail">
        <!-- 赞 -->
        <div class="interactive" @click="checkLike(videoInfo, 2)">
          <img src="@/assets/imgs/upHome/likeIconX.png" alt="" v-if="videoInfo.isLike" />
          <img src="@/assets/imgs/upHome/likeIcon.png" alt="" v-else />
          <div>{{ videoInfo.likes | numberFilter }}</div>
        </div>
        <!-- 踩 -->
        <div class="interactive" @click="checkLike(videoInfo, 3)">
          <img src="@/assets/imgs/upHome/weakIconX.png" alt="" v-if="videoInfo.isStampede" />
          <img src="@/assets/imgs/upHome/weakIcon.png" alt="" v-else />
          <div>{{ videoInfo.stampede | numberFilter }}</div>
        </div>
        <!-- 收藏 -->
        <div class="interactive" @click="checkLike(videoInfo, 1)">
          <img src="@/assets/imgs/upHome/collectIconX.png" alt="" v-if="videoInfo.isCollect" />
          <img src="@/assets/imgs/upHome/collectIcon.png" alt="" v-else />
          <div>{{ videoInfo.collects | numberFilter }}</div>
        </div>
        <!-- 分享 -->
        <div class="interactive comments" @click="share_fn">
          <img src="@/assets/imgs/upHome/shareIcon.png" alt="" />
          <div>分享</div>
        </div>
      </div>
    </div>
    <div class="other-main">
      <!-- 视频广告 -->
      <div v-if="$store.state.config.config.mediaPlayAdsType === 2 && iconVideoPlayAds.length" class="swiper">
        <AdAvatarList :list="iconVideoPlayAds" :wrap="true" key-name="avatar" />
      </div>
      <div class="swiperPack" v-else="$store.state.config.config.mediaPlayAdsType !== 3 && playAD.length">
        <EADSwiper
          :list="playAD"
          :slidesPerView="1"
          keyName="cover"
          :EADconfig="EADconfig"
          :imgCDN="imgCDN"
          @clickImg="clickImg"
        >
        </EADSwiper>
      </div>

      <div class="recommend-top">更多推荐</div>
      <ERefreshLoad
        @refreshData="refreshData"
        @moreData="moreData"
        :finished="finished"
        :loading="loading"
        :refreshing="refreshing"
      >
        <JavFourCard :list="videoLike" />
      </ERefreshLoad>

      <!-- 分享弹窗 -->
      <BlShare
        :isShow.sync="showShare"
        @select="shareBtn"
        @cancel="
          e => {
            showShare = e
          }
        "
      />
    </div>
    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>

<script>
import { video_play, video_like, video_pay } from 'api/play'
import { collect, advertiseClick } from 'api/home'
import { handleURlParams, handleParamsRouteJump } from '@/utils/utils_tools'
import { isWhiteList } from '@/components/web_base/utils_base/EUtils/utils_tools.js'
export default {
  name: 'Play',
  components: {
    JavVideo: () => import('@/components/JavVideo.vue'),
    JavFourCard: () => import('@/components/stencil/JavFourCard.vue'),
    BlShare: () => import('@/components/BlShare/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue')
  },
  inject: ['reload'],
  data() {
    return {
      videoInfo: {
        publisher: {}
      },
      videoLike: [],
      viedeoinfoAll: {},
      pageNum: 1,
      pageSize: 10,
      isDown: true,
      finished: false,
      loading: false,
      refreshing: false,
      skeleton: false,
      code: 0, // 是否可以播放code  6018。金币不足。6031/6032。 次数不足。   6033 需付费
      playable: false, // 是否可以播放
      showPorp: false,
      showShare: false,
      collectInfo: {
        mediaList: []
      },
      showBuy: false,
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 4000
      },
      lineSelectionShow: false,
      lineSelectionName: '主线路',
      lineSelectionList: ['主线路', '线路1', '线路2', '线路3', '线路4'],
      watchCount: 0,
      hourT: 0,
      hourC: 0
    }
  },
  created() {
    const minute = 1000 * 60
    const hour = minute * 60
    this.hourT = Date.parse(this.user.promotionExpiredAt) - new Date().getTime()
    this.hourC = (Date.parse(this.user.promotionExpiredAt) - new Date().getTime()) / hour
  },
  watch: {
    $route: {
      async handler(n, o) {
        if (!!o) {
          if (n.path != o.path) {
            if (!isWhiteList(this.hasPreview)) {
              this.reload()
            }
          }
        }
        await this.getVideoInfo()
        await this.refreshData()
        // if (this.videoInfo.subType === 1) {
        //   this.$store.dispatch('setAvList', {
        //     type: 'add',
        //     item: this.videoInfo,
        //   });
        // } else {

        // }
        this.$store.dispatch('setLongVideoList', {
          type: 'add',
          item: this.videoInfo
        })
      },
      immediate: true
    }
  },
  computed: {
    isVipMember({ $store }) {
      return $store.getters['isMember']
    },
    hasPreview() {
      // 只有不是免费视频才走下面一层逻辑
      if (this.playable && this.code === 200) {
        return true
      } else {
        return false
      }
    },
    // 长视频icon列表
    iconVideoPlayAds({ $store }) {
      return $store.getters['iconVideoPlayAds']
    },
    // 用户余额
    user({ $store }) {
      return $store.getters['getUserInfo']
    },
    playAD({ $store }) {
      return $store.getters['playAD']
    },
    shareUrl() {
      let url = this.$store.getters['shareUrl'] + '/'
      if (url[url.length - 1] == '/') {
        url = url.slice(0, url.length - 1)
      }
      return url
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {},
  methods: {
    fn_currentTime(time) {
      //存储本片播放历史记录
      this.videoInfo.currentTime = time
      this.$store.dispatch('setLongVideoList', {
        type: 'add',
        item: this.videoInfo
      })
    },
    fn_switch(item) {
      this.lineSelectionShow = false
      if (!this.isVipMember) {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '注意',
          content: `您当前还不是VIP，请前往充值`,
          type: 'play_post',
          ok: '',
          img: '',
          cancel: '确认',
          position: 'center',
          showBalance: false,
          cb: async returnData => {},
          close: async returnData => {}
        }
        return
      }
      // 如果用户是相应的VIP，执行以下操作
      this.lineSelectionName = item
      this.$refs.videoPlayNow.dp.currentTime = 0
    },

    async clickImg(item) {
      const code = handleURlParams(item.href)
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      const res = await advertiseClick({
        id: item.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },
    share_fn() {
      this.showShare = true
    },
    // 剪贴板
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },
    // 点击分享按钮
    shareBtn(options, index) {
      switch (index) {
        case 0:
          this.doCopy(
            this.shareUrl +
              '/#' +
              this.$route.path +
              '?pc=' +
              this.$store.getters['getUserInfo'].inviteCode +
              '&dc=h5share'
          )
          break
        case 1:
          this.doCopy(this.$store.getters['getUserInfo'].inviteUrl)
          break
        default:
          this.$router.push({ path: '/mine/myShouyi' })
          break
      }
    },
    // 列表刷新
    refreshData() {
      this.refreshing = true
      this.loading = true
      this.finished = false
      this.videoLike = []
      this.pageNum = 1
      this.getVideoLike()
      this.skeleton = true
    },
    // 加载更多
    moreData() {
      this.loading = true
      this.pageNum += 1
      this.getVideoLike()
    },
    // 跳过预览事件
    skipPreview(child) {
      this.showPorp = child
    },
    // 视频收藏接口
    async checkLike(item, type) {
      const res = await collect({
        collectType: item.videoType,
        flag: type == 1 ? !item.isCollect : type == 2 ? !item.isLike : !item.isStampede,
        object_id: item.id,
        type
        // type: type //1:收藏 2:点赞 3:踩
      })
      if (res.code === 200) {
        const typeMap = {
          1: { property: 'isCollect', counter: 'collects', successMsg: '收藏成功', failMsg: '收藏取消' },
          2: { property: 'isLike', counter: 'likes', successMsg: '点赞成功', failMsg: '点赞取消' },
          3: { property: 'isStampede', counter: 'stampede', successMsg: '踩踏成功', failMsg: '踩踏取消' }
        }
        if (typeMap.hasOwnProperty(type)) {
          const { property, counter, successMsg, failMsg } = typeMap[type]
          item[property] = !item[property]
          if (this.videoInfo[property]) {
            item[counter] += 1
            return this.$toast(successMsg)
          } else {
            item[counter] -= 1
            return this.$toast(failMsg)
          }
        }
      } else {
        return this.$toast(res.tip)
      }
    },
    // 获取视频信息
    async getVideoInfo() {
      try {
        const res = await video_play({
          id: +this.$route.params.id
        })
        if (res.code === 200) {
          this.collectInfo = res.data.collectInfo
          this.videoInfo = res.data.mediaInfo
          this.code = res.data.code
          this.playable = res.data.playable
          this.watchCount = res.data.watchCount
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试!')
      }
    },
    // 获取猜你喜欢列表
    async getVideoLike() {
      try {
        const res = await video_like({
          id: +this.$route.params.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.loading = false
          this.refreshing = false
          this.videoLike = [...this.videoLike, ...res.data.mediaList]
          if (!res.data.mediaList || (res.data.mediaList && res.data.mediaList.length < this.pageSize)) {
            this.finished = true
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
          return this.$toast(res.tip)
        }
      } catch (error) {
        this.loading = false
        this.refreshing = false
        this.finished = true
        console.log(error)
        return this.$toast('请求错误，请稍后再试!')
      }
    },
    //  视频购买接口
    async videoPlay(type) {
      try {
        const res = await video_pay({
          id: +this.$route.params.id,
          payType: type
        })
        if (res.data.code === 200) {
          this.$refs.videoPlayNow.skipVideoPlay()
          this.showPorp = false
          this.code = 200
          this.playable = true
          await this.getVideoInfo()
          await this.$store.dispatch('get_userInfo')
        } else {
          this.code = res.data.code
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试！')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.of-limits-area {
  width: 100%;
  height: 100%;
  max-height: 4.16rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > div:nth-child(1) {
    position: absolute;
    right: 0.82rem;
    top: 1.03rem;
    background: #00f6f8;
    width: 0.32rem;
    height: 0.32rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 0.3rem;
      height: 0.3rem;
    }
  }
  .info {
    > div {
      text-align: center;
      &:nth-child(1) {
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &:nth-child(2) {
        color: rgba(255, 255, 255, 0.5);
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0.24rem 0;
      }
      &:nth-child(3) {
        border-radius: 0.32rem;
        background: linear-gradient(272deg, #491ccb -11.14%, #8235ff 94.43%);
        padding: 0.08rem 0.32rem;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        > span:nth-child(2) {
          color: #f9c006;
          margin: 0 0.1rem;
        }
      }
    }
  }
}
.play-main {
  min-height: 100vh;
  padding-top: 4.06rem;
  background: url('~@/assets/imgs/mine/mine_top_bg.png');

  .return {
    font-size: 0.36rem;
    position: fixed;

    z-index: 999;

    :deep() {
      .van-nav-bar__placeholder {
        height: 0 !important;
      }
    }
  }

  .packFree {
    width: 100%;
    z-index: 2012;
    position: fixed;
    top: 0;
    max-width: $pcMaxWidth;

    .freeTimes {
      line-height: 0.54rem;
      padding: 0 0.16rem;
      border-radius: 0.12rem;
      background: rgba(0, 0, 0, 0.6);
      font-size: 0.24rem;
      color: #fff;
      position: absolute;
      top: 0.46rem;
      right: 0.32rem;
      z-index: 2012;

      span {
        color: #ff1e1e;
      }
    }
  }
}

.video-title {
  padding: 0.3rem 0.36rem 0 0.36rem;

  h2 {
    margin: 0;
    font-size: 0.34rem;
  }

  .video-duration {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.1rem 0;
    color: #787979;

    .lineSelection {
      width: 1.16rem;
      line-height: 0.42rem;
      border-radius: 0.08rem;
      border: 0.02rem solid #00f6f8;
      color: #00f6f8;
      font-size: 0.24rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 0.14rem;
        margin-left: 0.02rem;
      }

      ul {
        position: absolute;
        top: 0.42rem;
        left: 0;
        right: 0;
        border-radius: 0.08rem;
        border: 0.02rem solid #00f6f8;
        text-align: center;
        background: #000;

        li {
          line-height: 0.4rem;
          border-bottom: 0.02rem dashed #00f6f8;
        }
      }
    }
  }

  .tag-pack {
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;

    li {
      background: #00f6f8;
      font-size: 0.2rem;
      color: #333;
      border-radius: 0.08rem;
      height: 0.32rem;
      line-height: 0.32rem;
      padding: 0 0.1rem;
      margin-right: 0.1rem;
      white-space: nowrap;
    }
  }
  .desc {
    color: #787979;
    margin: 0.24rem 0 0;
    padding-bottom: 0.35rem;
    font-size: 0.28rem;
    position: relative;
    .font {
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      height: 1.2rem;
      font-size: 0.28rem;
    }

    h3 {
      font-size: 0.34rem;
      margin-bottom: 0.12rem;
    }
    .iconSty {
      position: absolute;
      right: 0;
      color: #71f2f6;
      font-size: 0.24rem;
      bottom: -0rem;
    }
  }

  .video-detail {
    @include flexbox();
    font-size: 0.28rem;
    color: #9493b1;
    margin-top: 0.15rem;

    .interactive {
      text-align: center;

      img {
        width: 0.48rem;
        height: 0.48rem;
      }

      div {
        font-size: 0.2rem;
        line-height: 0.31rem;
        color: #787979;
      }
    }
  }
}

.tag-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tag-txt {
  padding: 0.06rem 0.4rem;
  color: #fff;
  background: #00f6f8;
  border-radius: 0.08rem;
  font-size: 0.24rem;
  margin-right: 0.34rem;
  margin-bottom: 0.04rem;
  margin: 0.15rem 0.12rem 0.15rem 0;
}

.bango-txt {
  padding: 0.08rem 0.4rem;
  color: #848494;
  border: 0.01rem solid #adadad;
  border-radius: 0.25rem;
  font-size: 0.24rem;
}

.time-txt {
  font-size: 0.26rem;
  color: #848494;
}

.other-main {
  padding: 0.3rem 0.25rem;

  .recommend-top {
    margin-bottom: 0.3rem;
    font-size: 0.34rem;
  }

  .swiperPack {
    width: 100%;
    border-radius: 0.1rem;
    overflow: hidden;
  }
}

// 视频购买提示
.playWaring {
  width: 100%;
  z-index: 2013;
  height: 4.16rem;
  position: fixed;
  max-width: $pcMaxWidth;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);

  color: #fff;

  // 金币购买
  .goldTip,
  .vipTip,
  .goldTip-noGold {
    font-size: 0.3rem;
    width: 100%;
    height: 100%;
    text-align: right;
    max-height: 4.16rem;

    :deep() {
      .van-icon {
        margin-top: 0.3rem;
      }
    }

    &-first {
      @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);
      h2 {
        font-size: 0.32rem;
        margin: 0.2rem 0;
      }

      p {
        margin: 0;
      }

      p,
      span {
        font-size: 0.2rem;
      }

      .btn {
        margin: 0.5rem 0;
        width: 100%;
        text-align: center;
        @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
        font-size: 0.3rem;

        span {
          padding: 0.08rem 0.3rem;
          background: $btnBg;
          border-radius: 0.3rem;
          color: #333;
          margin-right: 0.3rem;
        }

        span:last-child {
          background: none;
          border: 0.02rem solid #fff;
          color: #fff;
        }
      }
    }
  }
}

// 新用户活动倒计时
.activity_time {
  background: linear-gradient(90deg, #033 0%, #0070c0 100%);
  width: 7.5rem;
  height: 0.72rem;
  margin-top: 0.2rem;
  @include flexbox($jc: flex-start);
  padding: 0 0.36rem;
  > span {
    white-space: nowrap;
  }
  img {
    width: 0.3rem;
    height: 0.27rem;
    margin-right: 0.2rem;
  }

  .btn {
    border-radius: 0.08rem;
    background: #ffcf86;
    padding: 0.04rem 0.16rem;
    color: #333;
  }

  :deep() {
    .van-count-down {
      font-size: 0.24rem;
      color: #fff;
      margin: 0 0.26rem;
    }

    .advertiseTimeOut-txt {
      margin: 0 0.1rem;
    }

    .advertiseTimeOut-block {
      display: inline-block;
      border-radius: 0.06rem;
      border: 1px solid #ffe381;
      background: #1b1b1b;
      width: 0.4rem;
      height: 0.34rem;
      text-align: center;
      line-height: 0.34rem;
    }
  }
}
.movie-tickets-btn,
.movie-gold-btn {
  @include flexbox();
  border-radius: 0.32rem;
  background: #886232;
  padding: 0.08rem 0.32rem;
  font-size: 0.3rem;
  img {
    width: 0.30857rem;
    height: 0.24rem;
    margin-right: 0.1rem;
  }
}
.movie-gold-btn {
  background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
  margin-right: 0.2rem;
  img {
    width: 0.32rem;
    height: 0.32rem;
  }
}
</style>
